<template>
  <div class="p-3">
    <appCheckEmploymentHistory
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckEmploymentHistory>
  </div>
</template>
<script>
import appCheckEmploymentHistory from "../../components/checkEmploymentHistory/checkEmploymentHistory";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "CheckEmploymentHistory",
  components: {
    appCheckEmploymentHistory,
  },
  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
};
</script>